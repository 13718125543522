import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import ContactCard from "../components/Helpers/contactCard";
import styled from "@emotion/styled";
import { TertiaryNav } from "../components/Helpers/TertiaryNav";
import FacebookTimeline from "../components/Helpers/FacebookTimeline";
import { screenSize } from "../utils/css/themes";
import { useEffect } from "react";
import DangerousHtml from "../components/Helpers/DangerousHtml";
import SEO from "../components/seo";

const NonprofitPage = (props: any) => {
  const ErrorLayout = () => (
    <Layout title="Nonprofit Page">
      <p>Could not retrieve post data.</p>
    </Layout>
  );

  if (!props?.data?.wpPost?.nonprofitPageSubPages) {
    return <ErrorLayout />;
  }

  const sub_page_title = props?.pageContext?.title;
  const detailPage = props.data.wpPost;
  const contact = detailPage.nonprofitPageSubPages?.contact[0];
  const isBrowser = typeof window !== `undefined`;
  const currentPath = props.location?.pathname;
  const isSocialPage = currentPath.includes("social-media");
  const isContactPage = currentPath.includes("contact");

  useEffect(() => {
    const link = props.location.state?.previousPageLink;
    const page = props.location.state?.previousPage;
    if (isBrowser) {
      link && window.localStorage.setItem("link", link);
      page && window.localStorage.setItem("page", page);
    }
  }),
    [];

  const previousLink = isBrowser && window.localStorage.getItem("link");
  const DetailTitle = styled.h1`
    margin-top: 2rem;
  `;
  const FacebookContainer = styled.div`
    max-width: 100%;
  `;
  const SocialMediaDiv = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: ${screenSize.largeTablet}) {
      flex-direction: column;
      align-items: center;
    }
  `;
  const StyledDiv = styled.div`
    margin-bottom: 60px;
  `;
  const tertiaryNav = detailPage.nonprofitPageSubPages?.subPages.map(
    (page: SubPage, i: number) => ({
      page_title: page.title,
      page_slug: `/${detailPage.slug}/${
        i > 0 ? page.title.toLowerCase().trim().replace(" ", "-") : ""
      }`,
    })
  );

  if (detailPage?.nonprofitPageSubPages?.socialMedia) {
    tertiaryNav.push({
      page_title: "Social Media",
      page_slug: `/${detailPage.slug}/social-media`,
    });
  }
  if (detailPage?.nonprofitPageSubPages?.contact) {
    tertiaryNav.push({
      page_title: "Contact",
      page_slug: `/${detailPage.slug}/contact`,
    });
  }

  return (
    <Layout
      backButton={previousLink && { label: "Issue", link: "/" + previousLink }}
      title={detailPage.title}
    >
      <SEO title={detailPage.title} />

      <TertiaryNav
        tertiaryPages={tertiaryNav}
        DefaultPage={props.pageContext.title}
      ></TertiaryNav>
      <DetailTitle>{sub_page_title}</DetailTitle>
      <DangerousHtml>{props.pageContext.content}</DangerousHtml>
      {sub_page_title == "Contact" && contact && (
        <ContactCard
          {...contact}
          fluid={
            detailPage.nonprofitPageSubPages?.logo?.localFile?.childImageSharp
              ?.gatsbyImageData
          }
        />
      )}

      {isSocialPage && (
        <div>
          {detailPage.nonprofitPageSubPages.socialMedia.textContent && (
            <StyledDiv
              dangerouslySetInnerHTML={{
                __html:
                  detailPage.nonprofitPageSubPages.socialMedia.textContent,
              }}
            />
          )}
          <SocialMediaDiv>
            {detailPage.nonprofitPageSubPages.socialMedia.facebookLink && (
              <FacebookContainer>
                {detailPage.nonprofitPageSubPages.socialMedia.facebookLink && (
                  <FacebookTimeline
                    page={
                      detailPage.nonprofitPageSubPages.socialMedia.facebookLink.slice(
                        -1
                      ) === "/"
                        ? detailPage.nonprofitPageSubPages.socialMedia.facebookLink.slice(
                            0,
                            -1
                          )
                        : detailPage.nonprofitPageSubPages.socialMedia
                            .facebookLink
                    }
                  />
                )}
              </FacebookContainer>
            )}
          </SocialMediaDiv>
        </div>
      )}
    </Layout>
  );
};
export default NonprofitPage;

export const pageQuery = graphql`
  query ($id: String = "") {
    wpPost(id: { eq: $id }) {
      ...nonprofitPageQuery
    }
  }
`;

interface SubPage {
  title: string;
  content: string;
}
