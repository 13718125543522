import * as React from "react";
import styled from "@emotion/styled";
import { screenSize, quicksandFont } from "../../utils/css/themes";

/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { GatsbyImage } from "gatsby-plugin-image";

const ContactHeader = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
`;

const HeaderText = styled.h2`
  margin: 0;
  font-size: 24px;
  padding: 1rem 1rem;
`;

const ExtNavBoxStyle = styled.div`
  width: 85%;
  margin: 15px 0;
  @media (max-width: ${screenSize.smallTablet}) {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  word-wrap: break-word;
  :last-child {
    margin-left: 25px;
  }
  @media (max-width: ${screenSize.smallTablet}) {
    :last-child {
      margin-left: 0px;
    }
  }
  p {
    margin: 0;
    line-height: 2rem;
    font-size: 18px;
    label {
      font-family: ${quicksandFont.light};
    }
  }
`;

const ExtBoxesContainer = styled.div`
  display: flex;
  margin: auto;
  justify-content: space-around;
  margin: 40px 0px;
  @media (max-width: ${screenSize.smallTablet}) {
    flex-direction: column;
    margin: 1rem auto;
  }
`;
const ContentGrid = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 115px 20px 100px;
  line-height: 1.71;
  @media (max-width: ${screenSize.smallTablet}) {
    flex-direction: column;
    margin: 1rem auto;
    padding: 5px 15px;
  }
`;
const ImgCSS = css`
  max-width: 90%;
  margin: 20px auto;
`;
const ContactCard = (props: Contact) => {
  const google_maps_url = `http://google.com/maps/place/${(
    props.addressLine1 + props.addressLine2
  ).replace(" ", "+")}`;
  const email_url = `mailto:${props.email}`;
  const phone_url = `tel:${props.workPhone}`;
  //string.charAt(0).toUpperCase() + string.slice(1);
  return (
    <ExtBoxesContainer>
      <ExtNavBoxStyle>
        <ContactHeader></ContactHeader>
        {props.gatsbyImageData && (
          <GatsbyImage
            css={ImgCSS}
            alt={"site background"}
            image={props.gatsbyImageData}
          />
        )}
        <ContentGrid>
          <ContentContainer>
            {props.name && (
              <p>
                <label
                  css={css`
                    font-weight: bold;
                  `}
                >
                  Name:&nbsp;
                </label>
                {props.title ? (
                  <span>
                    {props.title} {props.name}
                  </span>
                ) : (
                  <span>{props.name}</span>
                )}
              </p>
            )}
            {props.organization && (
              <p>
                <label
                  css={css`
                    font-weight: bold;
                  `}
                >
                  Organization:&nbsp;
                </label>
                {props.organization}
              </p>
            )}
            {props.email && (
              <p>
                <label
                  css={css`
                    font-weight: bold;
                  `}
                >
                  Email:&nbsp;
                </label>
                <a href={email_url}>{props.email}</a>
              </p>
            )}
            {props.workPhone && (
              <p>
                <label
                  css={css`
                    font-weight: bold;
                  `}
                >
                  Work:&nbsp;
                </label>
                <a href={phone_url}>{props.workPhone}</a>
              </p>
            )}
          </ContentContainer>
          <ContentContainer>
            {props.website && (
              <p>
                <label
                  css={css`
                    font-weight: bold;
                  `}
                >
                  Website:&nbsp;
                </label>
                <a href={props.website}>{props.website}</a>
              </p>
            )}
            {props.addressLine1 && props.addressLine2 && (
              <p>
                <label
                  css={css`
                    font-weight: bold;
                  `}
                >
                  Address:&nbsp;
                </label>
                <br />
                {props.addressLine1}
                <br />
                {props.addressLine2}
                <br />
                <a href={google_maps_url}>Google Maps</a>
              </p>
            )}
          </ContentContainer>
        </ContentGrid>
      </ExtNavBoxStyle>
    </ExtBoxesContainer>
  );
};

interface Contact {
  title?: string;
  name?: string;
  organization?: string;
  email?: string;
  workPhone?: string;
  addressLine1?: string;
  addressLine2?: string;
  website?: string;
  gatsbyImageData?: any;
}
export default ContactCard;
